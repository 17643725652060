import React from 'react';
import LoginForm from '../modules/Auth/LoginForm/LoginForm';
import { Helmet } from 'react-helmet';
interface Props {}

const Login = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Login</title>
			</Helmet>
			<LoginForm />
		</>
	);
};

export default Login;
