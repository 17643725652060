import { Link, navigate } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useContext, useMemo, useState } from 'react';
import { AppButton } from '../../../components/core/AppButton';
import AppInput from '../../../components/core/AppInput/AppInput';
import useStartPagePath from '../../../hooks/useStartPagePath';
import { authStoreContext } from '../../../stores/AuthStore';
import { checkEmail } from '../../../utils/validation';
import axios from 'axios';

interface Props {}

const LoginForm = (props: Props) => {
	const { login } = useContext(authStoreContext);

	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);
	const startPagePath = useStartPagePath();

	const disabled = useMemo(() => password.length < 5 || !checkEmail(email), [password, email]);

	const handleLogin = () => {
		setError(false);

		setLoading(true);

		let ipAddress;
		let country;
		axios.get('https://ipapi.co/json/').then((res)=>{
			ipAddress=res.data.ip;
			country=res.data.country_name;
		})
			.catch((err)=>{console.log(err?.response)})
			.finally(()=>{
				login({ email, password, ipAddress, country})
					.then(() => {
						navigate(startPagePath);
					})
					.catch(() => setError(true))
					.finally(() => {
						setLoading(false);
					});
		})

	};

	return (
		<div className="centered container">
			<form method="post">
				<div className="w-72 flex flex-col gap-4">
					<h1 className="text-4xl">Login</h1>
					<AppInput
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						type="text"
						placeholder="Email"
						name="email"
						autoComplete="on"
					/>
					<AppInput
						onChange={(e) => setPassword(e.target.value)}
						value={password}
						type="password"
						placeholder="Password"
						name="password"
						autoComplete="on"
					/>
					<Link className="text-center" to="/password/reset">
						Forgot password?
					</Link>
					<AppButton type="submit" onClick={handleLogin} disabled={disabled || loading}>
						Login
					</AppButton>
					{error && <span className="text-red-400">Error happened, check credentials</span>}
				</div>
			</form>
		</div>
	);
};

export default observer(LoginForm);
